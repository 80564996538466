import styles from "./AddAutopay.module.css";
import { Col, Row } from "react-grid-system";
import StyledButton from "../../common/buttons/StyledButton";
import Subtool from "../../common/Subtool";
import PaymentAmountSelector from "../../ManageAutopay/AddAutopay/PaymentAmountSelector";
import {
  OWNERSHIP_OPTIONS,
  isCardMemberAccount,
} from "../../../lib/sourceAccounts";
import SourceAccountSelect from "../../MakePayment/SourceAccountSelect";
import Select from "../../common/Select";
import StyledInput from "../../common/inputs/StyledInput";
import {
  abaNumberMaxLength,
  sourceAccountAccountNumberMaxLength,
  sourceAccountAccountNumberMinLength,
} from "../../../lib/constants/lengths";
import StyledPasswordInput from "../../StyledPasswordInput";
import PhoneNumberInput from "../../common/PhoneNumberInput";
import { FALLBACK_SUPPORTED_STATES } from "../../../lib/api/fallback.data";
import StyledZipcodeInput from "../../common/StyledZipcodeInput";
import { useMemo, useState } from "react";
import {
  GetAccountDetailsResponse,
  GetAccountResponse,
  OwnershipType,
  PaymentMethod,
  ValidationErrors,
} from "../../../lib/api/models";
import { maskInput, INPUT_MASKS } from "../../../lib/formatting";
import { useIsAgentRoleViewPCIData } from "../../../lib/roleContext";
import { NEW_SOURCE_ACCOUNT } from "../../MakePayment/contants";
import { useAddAutopayForm } from "./AddAutopay.hooks";
import {
  useAddBankSourceAccount,
  useGetBankNames,
  useGetPaymentMethods,
} from "../../../lib/api/queries";
import Spinner from "../../common/Spinner";
import StyledError from "../../StyledError";
import { reportError } from "../../../lib/bugReporting";
import { setValidationErrorsAndDisplayGeneralErrors } from "../../../lib/validation";

const strings = {
  one: "1",
  two: "2",
  holderAndAccountInfoSubheader: "Holder and Account Info",
  ownership: "Ownership*",
  select: "Select",
  amountSubheader: "Amount",
  enroll: "Enroll in Autopay",
  abaNumber: "ABA Number",
  abaNumberRequired: "ABA Number*",
  bankNameRequired: "Bank Name*",
  accountNumber: "Account Number",
  accountNumberRequired: "Account Number*",
  confirmAccountNumber: "Confirm Account Number*",
  cardHolderFirstName: "First Name*",
  cardHolderFirstName2: "First Name 2",
  cardHolderLastName: "Last Name*",
  cardHolderLastName2: "Last Name 2",
  phoneNumber: "Phone Number",
  address: "Address*",
  unit: "Unit or Ste.",
  city: "City*",
  state: "State*",
  zip: "Zip*",
  addSourceAccount: "Add Source Account",
  autofill: "Autofill",
  accountNumbersDontMatch: "Account Numbers don't match",
  addAutopay: "Add Autopay",
  somethingWentWrong: "Something went wrong loading bank names",
};

type Props = {
  account: GetAccountResponse;
  accountDetails: GetAccountDetailsResponse;
  onDisplayGeneralErrors: (err: unknown) => void;
  className?: string;
};

const validateAccountNumbers = (
  accNumber: string,
  confirmAccNumber: string,
): string[] => {
  if (!confirmAccNumber) {
    return [];
  }

  if (accNumber !== confirmAccNumber) {
    return [strings.accountNumbersDontMatch];
  }

  return [];
};

export default function AddAutopay({
  account,
  accountDetails,
  onDisplayGeneralErrors,
  className,
}: Props) {
  const canViewPCIData = useIsAgentRoleViewPCIData();

  const [formState, { generalDispatch }] = useAddAutopayForm();
  const [networkValidationMessages, setNetworkValidationMessages] =
    useState<ValidationErrors>();
  const [localValidationMessages, setLocalValidationMessages] = useState<
    ValidationErrors | undefined
  >(undefined);

  const {
    data: getPaymentMethodsResponse,
    isLoading: isGetPaymentMethodsResponseLoading,
    isError: isGetPaymentMethodsResponseError,
    error: getPaymentMethodsResponseError,
    refetch: getPaymentMethodsResponseRefetch,
  } = useGetPaymentMethods({
    partyId: account.partyId,
  });

  const {
    data: bankNamesResponse,
    isLoading: isLoadingBankNames,
    isError: isBankNamesError,
    error: bankNamesError,
    refetch: refetchBankNames,
  } = useGetBankNames(
    formState.abaNumber.length === abaNumberMaxLength
      ? formState.abaNumber
      : "",
  );

  const {
    mutateAsync: addBankSourceAccount,
    isLoading: isAddBankSourceAccountLoading,
  } = useAddBankSourceAccount();

  const isAddingNewSourceAccount =
    formState.selectedSourceAccountId === NEW_SOURCE_ACCOUNT;
  const isOwner = isCardMemberAccount({
    ownerType: formState.ownerType ?? "UNKNOWN",
  });
  const isLoading =
    isGetPaymentMethodsResponseLoading || isAddBankSourceAccountLoading;
  const sourceAccountInfoInputDisabled = isLoading || !isAddingNewSourceAccount;

  const { sourceAccountOptions } = useMemo(() => {
    if (!getPaymentMethodsResponse) {
      return { sourceAccountOptions: [] };
    }

    let currentPM: PaymentMethod[] =
      getPaymentMethodsResponse.thirdPartyAccounts;
    switch (formState.ownerType) {
      case "ACCOUNT_OWNER":
        currentPM = getPaymentMethodsResponse.cardMemberAccounts.filter(
          (pm) => pm.ownerType === "ACCOUNT_OWNER",
        );
        break;
      case "AUTHORIZED_USER":
        currentPM = getPaymentMethodsResponse.cardMemberAccounts.filter(
          (pm) => pm.ownerType === "AUTHORIZED_USER",
        );
    }

    currentPM = currentPM.filter((pm) => pm.type === "checking_account");

    return { sourceAccountOptions: currentPM };
  }, [getPaymentMethodsResponse, formState.ownerType]);

  const clearSourceAccountInfo = () => {
    generalDispatch({
      abaNumber: "",
      selectedBankName: "",
      accountNumber: "",
      confirmAccountNumber: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      firstName2: undefined,
      lastName2: undefined,
      addressLine1: "",
      addressLine2: undefined,
      city: "",
      selectedStateCode: "",
      zipcode: "",
    });
    setNetworkValidationMessages(undefined);
    setLocalValidationMessages(undefined);
  };
  const clearNetworkValidationMessage = (...keys: string[]) => {
    if (!networkValidationMessages || keys.length < 1) {
      return;
    }

    setNetworkValidationMessages((prev) => {
      const next = { ...prev };
      keys.forEach((key) => {
        next[key] = [];
      });

      return next;
    });
  };

  const onUpdateOwnership = (v: OwnershipType) => {
    generalDispatch({
      ownerType: v,
      selectedSourceAccountId: "",
    });
    clearSourceAccountInfo();
  };

  const onUpdateSelectedSourceAccount = (id: string) => {
    clearSourceAccountInfo();
    if (id === NEW_SOURCE_ACCOUNT) {
      generalDispatch({
        selectedSourceAccountId: id,
      });
      return;
    }

    const sourceAccount = sourceAccountOptions.find((acc) => acc.id === id);
    if (!sourceAccount) {
      reportError(
        new Error("Programmer Error: Unable to find source account with id", {
          cause: id,
        }),
      );
      return;
    }

    if (sourceAccount.type === "checking_account") {
      if (!isOwner) {
        generalDispatch({
          firstName: sourceAccount.cardHolderFirstName,
          lastName: sourceAccount.cardHolderLastName,
          firstName2: sourceAccount.cardHolderFirstName2 ?? undefined,
          lastName2: sourceAccount.cardHolderLastName2 ?? undefined,
        });
      } else {
        generalDispatch({
          firstName: sourceAccount.cardHolderFirstName,
          lastName: sourceAccount.cardHolderLastName,
          firstName2: undefined,
          lastName2: undefined,
        });
      }

      generalDispatch({
        selectedSourceAccountId: id,
        abaNumber: sourceAccount.abaNumber,
        accountNumber: sourceAccount.accountNumber,
        selectedBankName: "",
        addressLine1: sourceAccount.addressLine1,
        addressLine2: sourceAccount.addressLine2 ?? undefined,
        phoneNumber: sourceAccount.phoneNumber ?? "",
        city: sourceAccount.city,
        selectedStateCode: sourceAccount.stateCode,
        zipcode: sourceAccount.zipcode,
      });
    } else {
      reportError(
        new Error(
          "Programmer Error: Selected source account is not of type checking_account",
          {
            cause: {
              id,
              type: sourceAccount.type,
            },
          },
        ),
      );
      return;
    }
  };

  const accountNumberInputsOnFocus = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber: [],
      };
    });
  };

  const accountNumberInputsOnBlur = () => {
    setLocalValidationMessages((prev) => {
      return {
        ...prev,
        confirmAccountNumber: validateAccountNumbers(
          formState.accountNumber,
          formState.confirmAccountNumber,
        ),
      };
    });
  };

  const onAddSourceAccount = (e: React.FormEvent) => {
    e.preventDefault();

    if (!formState.canAddSourceAccount || !formState.ownerType) {
      return;
    }

    const checkingsSourceAccountRequest = {
      partyId: account.partyId,
      ownerType: formState.ownerType,
      sourceAccountId: formState.selectedSourceAccountId,
      accountNumber: formState.accountNumber,
      abaNumber: formState.abaNumber,
      addressLine1: formState.addressLine1,
      addressLine2: formState.addressLine2,
      city: formState.city,
      stateCode: formState.selectedStateCode,
      zipcode: formState.zipcode,
      phoneNumber: formState.phoneNumber,
      cardHolderFirstName: formState.firstName,
      cardHolderLastName: formState.lastName,
      cardHolderFirstName2: formState.firstName2,
      cardHolderLastName2: formState.lastName2,
    };

    addBankSourceAccount(checkingsSourceAccountRequest)
      .then((sourceAccount: PaymentMethod) => {
        if (sourceAccount.type === "checking_account") {
          generalDispatch({
            selectedSourceAccountId: sourceAccount.id,
            abaNumber: sourceAccount.abaNumber,
            selectedBankName:
              sourceAccount.bankName ?? formState.selectedBankName,
            accountNumber: sourceAccount.accountNumber,
            firstName: sourceAccount.cardHolderFirstName,
            firstName2: sourceAccount.cardHolderFirstName2 ?? undefined,
            lastName: sourceAccount.cardHolderLastName,
            lastName2: sourceAccount.cardHolderLastName2 ?? undefined,
            addressLine1: sourceAccount.addressLine1,
            addressLine2: sourceAccount.addressLine2 ?? undefined,
            city: sourceAccount.city,
            selectedStateCode: sourceAccount.stateCode,
            zipcode: sourceAccount.zipcode,
            phoneNumber: sourceAccount.phoneNumber ?? "",
          });
        } else {
          reportError(
            new Error(
              "Programmer Error: Response source account is not type `checking_account`",
              {
                cause: {
                  id: sourceAccount.id,
                  type: sourceAccount.type,
                },
              },
            ),
          );
        }
      })
      // TODO: Dynamic Error Handling
      .catch((err) =>
        setValidationErrorsAndDisplayGeneralErrors(
          err,
          setNetworkValidationMessages,
          onDisplayGeneralErrors,
        ),
      );
  };

  const onAutofill = () => {
    generalDispatch({
      firstName: account.firstName,
      lastName: account.lastName,
      phoneNumber: accountDetails.phoneNumber,
      addressLine1: accountDetails.addressLine1,
      addressLine2: accountDetails.addressLine2 ?? undefined,
      city: accountDetails.city,
      selectedStateCode: accountDetails.stateCode,
      zipcode: accountDetails.zipcode,
    });

    clearNetworkValidationMessage(
      "firstName",
      "lastName",
      "phoneNumber",
      "addressLine1",
      "addressLine2",
      "city",
      "selectedStateCode",
      "zipcode",
    );
  };

  const renderAddAutopay = () => {
    return (
      <>
        <Row>
          <Col md={8}>
            <Subtool>
              <div className={styles.subheaderContainer}>
                <div className={styles.subtoolNumberOne}>{strings.one}</div>
                <div className={styles.subheader}>
                  {strings.holderAndAccountInfoSubheader}
                </div>
              </div>

              <form onSubmit={onAddSourceAccount}>
                <Row className={styles.row}>
                  <Col md={3}>
                    <Select
                      selectedValue={formState.ownerType}
                      options={OWNERSHIP_OPTIONS}
                      onChange={(v: OwnershipType) => {
                        onUpdateOwnership(v);
                        clearNetworkValidationMessage("ownerType");
                      }}
                      label={strings.ownership}
                      placeholder={strings.select}
                      validationMessages={
                        networkValidationMessages
                          ? networkValidationMessages["ownerType"]
                          : undefined
                      }
                      disabled={isLoading}
                    />
                  </Col>
                  <Col md={3}>
                    <SourceAccountSelect
                      className={styles.sourceAccountSelect}
                      selectedValue={formState.selectedSourceAccountId}
                      accounts={sourceAccountOptions}
                      onChange={(v) => onUpdateSelectedSourceAccount(v)}
                      onAddNew={(v) => onUpdateSelectedSourceAccount(v)}
                      disabled={!formState.ownerType || isLoading}
                    />
                  </Col>
                  {formState.ownerType === "ACCOUNT_OWNER" &&
                  formState.selectedSourceAccountId ? (
                    <Col
                      className={styles.autofillBtnCol}
                      offset={{ md: 4.5 }}
                      md={1.5}
                    >
                      <StyledButton
                        className={styles.autofillBtn}
                        variant="secondary"
                        disabled={sourceAccountInfoInputDisabled}
                        onClick={onAutofill}
                      >
                        {strings.autofill}
                      </StyledButton>
                    </Col>
                  ) : null}
                </Row>
                {formState.selectedSourceAccountId ? (
                  <>
                    <Row className={styles.row}>
                      <Col md={3}>
                        <StyledInput
                          label={
                            isAddingNewSourceAccount
                              ? strings.abaNumberRequired
                              : strings.abaNumber
                          }
                          value={formState.abaNumber}
                          onChange={(e) => {
                            const newValue = maskInput(
                              INPUT_MASKS.abaNumber,
                              e.currentTarget.value,
                            );

                            generalDispatch({
                              abaNumber: newValue,
                            });
                            if (
                              formState.selectedBankName &&
                              newValue.length !== abaNumberMaxLength
                            ) {
                              generalDispatch({ selectedBankName: "" });
                            }
                            clearNetworkValidationMessage("abaNumber");
                          }}
                          maxLength={abaNumberMaxLength}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["abaNumber"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <Select
                          selectedValue={formState.selectedBankName}
                          options={
                            bankNamesResponse?.bankNames.map((n) => ({
                              label: n,
                              value: n,
                            })) ?? []
                          }
                          onChange={(v: string) =>
                            generalDispatch({ selectedBankName: v })
                          }
                          label={strings.bankNameRequired}
                          placeholder={strings.select}
                          disabled={
                            isLoadingBankNames ||
                            !bankNamesResponse ||
                            sourceAccountInfoInputDisabled
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <StyledPasswordInput
                          label={
                            isAddingNewSourceAccount
                              ? strings.accountNumberRequired
                              : strings.accountNumber
                          }
                          value={formState.accountNumber}
                          onChange={(e) => {
                            generalDispatch({
                              accountNumber: maskInput(
                                INPUT_MASKS.checkingAccountNumber,
                                e.currentTarget.value,
                              ),
                            });
                            clearNetworkValidationMessage("accountNumber");
                          }}
                          maxLength={sourceAccountAccountNumberMaxLength}
                          minLength={sourceAccountAccountNumberMinLength}
                          alwaysHideInput={!canViewPCIData}
                          onFocus={accountNumberInputsOnFocus}
                          onBlur={accountNumberInputsOnBlur}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["accountNumber"]
                              : undefined
                          }
                        />
                      </Col>
                      {isAddingNewSourceAccount ? (
                        <Col md={3}>
                          <StyledPasswordInput
                            label={strings.confirmAccountNumber}
                            value={formState.confirmAccountNumber}
                            onChange={(e) =>
                              generalDispatch({
                                confirmAccountNumber: maskInput(
                                  INPUT_MASKS.checkingAccountNumber,
                                  e.currentTarget.value,
                                ),
                              })
                            }
                            maxLength={sourceAccountAccountNumberMaxLength}
                            minLength={sourceAccountAccountNumberMinLength}
                            alwaysHideInput={!canViewPCIData}
                            onFocus={accountNumberInputsOnFocus}
                            onBlur={accountNumberInputsOnBlur}
                            validationMessages={
                              localValidationMessages?.confirmAccountNumber
                            }
                            disabled={sourceAccountInfoInputDisabled}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    {isBankNamesError ? (
                      <Row>
                        <Col>
                          <StyledError
                            error={bankNamesError}
                            errorTitle={strings.somethingWentWrong}
                            refetch={refetchBankNames}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <Row className={styles.row}>
                      <Col md={3}>
                        <StyledInput
                          label={strings.cardHolderFirstName}
                          value={formState.firstName}
                          onChange={(e) => {
                            generalDispatch({
                              firstName: e.currentTarget.value,
                            });
                            clearNetworkValidationMessage(
                              "cardHolderFirstName",
                            );
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["cardHolderFirstName"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <StyledInput
                          label={strings.cardHolderLastName}
                          value={formState.lastName}
                          onChange={(e) => {
                            generalDispatch({
                              lastName: e.currentTarget.value,
                            });
                            clearNetworkValidationMessage("cardHolderLastName");
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["cardHolderLastName"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={3}>
                        <PhoneNumberInput
                          label={strings.phoneNumber}
                          value={formState.phoneNumber}
                          onChange={(v) => {
                            generalDispatch({ phoneNumber: v });
                            clearNetworkValidationMessage("phoneNumber");
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["phoneNumber"]
                              : undefined
                          }
                        />
                      </Col>
                    </Row>
                    {!isOwner ? (
                      <Row className={styles.row}>
                        <Col md={3}>
                          <StyledInput
                            label={strings.cardHolderFirstName2 ?? ""}
                            value={formState.firstName2}
                            onChange={(e) => {
                              generalDispatch({
                                firstName2: e.currentTarget.value,
                              });
                              clearNetworkValidationMessage(
                                "cardHolderFirstName2",
                              );
                            }}
                            disabled={sourceAccountInfoInputDisabled}
                            validationMessages={
                              networkValidationMessages
                                ? networkValidationMessages[
                                    "cardHolderFirstName2"
                                  ]
                                : undefined
                            }
                          />
                        </Col>
                        <Col md={3}>
                          <StyledInput
                            label={strings.cardHolderLastName2 ?? ""}
                            value={formState.lastName2}
                            onChange={(e) => {
                              generalDispatch({
                                lastName2: e.currentTarget.value,
                              });
                              clearNetworkValidationMessage(
                                "cardHolderLastName2",
                              );
                            }}
                            disabled={sourceAccountInfoInputDisabled}
                            validationMessages={
                              networkValidationMessages
                                ? networkValidationMessages[
                                    "cardHolderLastName2"
                                  ]
                                : undefined
                            }
                          />
                        </Col>
                      </Row>
                    ) : null}
                    <Row className={styles.row}>
                      <Col md={4.5}>
                        <StyledInput
                          label={strings.address}
                          value={formState.addressLine1}
                          onChange={(e) => {
                            generalDispatch({
                              addressLine1: e.currentTarget.value,
                            });
                            clearNetworkValidationMessage("addressLine1");
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["addressLine1"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={1.5}>
                        <StyledInput
                          label={strings.unit}
                          value={formState.addressLine2 ?? ""}
                          onChange={(e) => {
                            generalDispatch({
                              addressLine2: e.currentTarget.value,
                            });
                            clearNetworkValidationMessage("addressLine2");
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["addressLine2"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <StyledInput
                          label={strings.city}
                          value={formState.city}
                          onChange={(e) => {
                            generalDispatch({ city: e.currentTarget.value });
                            clearNetworkValidationMessage("city");
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["city"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={FALLBACK_SUPPORTED_STATES}
                          label={strings.state}
                          selectedValue={formState.selectedStateCode}
                          onChange={(v) => {
                            generalDispatch({ selectedStateCode: v });
                            clearNetworkValidationMessage("stateCode");
                          }}
                          placeholder={strings.select}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["stateCode"]
                              : undefined
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <StyledZipcodeInput
                          className={styles.zipcode}
                          label={strings.zip}
                          value={formState.zipcode}
                          onChange={(v) => {
                            generalDispatch({ zipcode: v });
                            clearNetworkValidationMessage("zipcode");
                          }}
                          disabled={sourceAccountInfoInputDisabled}
                          validationMessages={
                            networkValidationMessages
                              ? networkValidationMessages["zipcode"]
                              : undefined
                          }
                        />
                      </Col>
                    </Row>
                    <Row className={styles.row}>
                      <Col offset={{ md: 9 }} md={3}>
                        <StyledButton
                          variant="secondary"
                          className={styles.addSourceAccount}
                          type="submit"
                          disabled={
                            !formState.canAddSourceAccount ||
                            sourceAccountInfoInputDisabled
                          }
                          isLoading={isAddBankSourceAccountLoading}
                        >
                          {strings.addSourceAccount}
                        </StyledButton>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </form>
            </Subtool>
          </Col>

          <Col md={4}>
            <Subtool>
              <div className={styles.subheaderContainer}>
                <div className={styles.subtoolNumberTwo}>{strings.two}</div>
                <div className={styles.subheader}>
                  {strings.amountSubheader}
                </div>
              </div>

              <form onSubmit={() => null}>
                <PaymentAmountSelector
                  customInput=""
                  onSelectPaymentAmount={() => null}
                  onChangeCustomInput={() => null}
                  className={styles.selector}
                  onBlurCustomInput={() => null}
                />
                <StyledButton
                  className={styles.enrollmentButton}
                  variant="primary"
                  type="submit"
                >
                  {strings.enroll}
                </StyledButton>
              </form>
            </Subtool>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className={className}>
      {isGetPaymentMethodsResponseLoading ? (
        <Row>
          <Col>
            <Spinner className={styles.spinner} />
          </Col>
        </Row>
      ) : isGetPaymentMethodsResponseError ? (
        <Row>
          <Col>
            <StyledError
              error={getPaymentMethodsResponseError}
              errorTitle={strings.addAutopay}
              refetch={getPaymentMethodsResponseRefetch}
            />
          </Col>
        </Row>
      ) : (
        renderAddAutopay()
      )}
    </div>
  );
}
