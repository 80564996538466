import { ToolType } from ".";

export function toolTypeToName(toolType: ToolType): string {
  switch (toolType) {
    case ToolType.ChangeUsername:
      return "Change Username";
    case ToolType.TestPushNotification:
      return "Test Push Notification";
    case ToolType.PaymentTransactions:
      return "Payment Transactions";
    case ToolType.PaymentHistory:
      return "Payment History";
    case ToolType.ManageAutopay:
      return "Manage Autopay";
    case ToolType.MakePayment:
      return "Make Payment";
    case ToolType.SourceAccounts:
      return "Source Accounts";
    case ToolType.PermissionToDiscuss:
      return "Permission to Discuss";
    case ToolType.CycleToDateTransactions:
      return "Cycle to Date Transactions";
    case ToolType.StatementHistory:
      return "Statement History";
    case ToolType.LostStolen:
      return "Process Lost/Stolen";
    case ToolType.CreditLineIncrease:
      return "Credit Line Increase";
    case ToolType.Terms:
      return "Terms";
    case ToolType.Autopay:
      return "Autopay";
  }
}
