import { useState } from "react";
import { OwnershipType } from "../../../lib/api/models";
import { NEW_SOURCE_ACCOUNT } from "../../MakePayment/contants";

export type AddAutopayFormInput = {
  ownerType?: OwnershipType;
  selectedSourceAccountId: string;
  abaNumber: string;
  selectedBankName: string;
  accountNumber: string;
  confirmAccountNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  firstName2?: string;
  lastName2?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  selectedStateCode: string;
  zipcode: string;
  isComplete: boolean;
  isDirty: boolean;
  canAddSourceAccount: boolean;
};

const INITIAL_SOURCE_ACCOUNT_FORM_STATE: AddAutopayFormInput = {
  ownerType: undefined,
  selectedSourceAccountId: "",
  abaNumber: "",
  selectedBankName: "",
  accountNumber: "",
  confirmAccountNumber: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  addressLine1: "",
  city: "",
  selectedStateCode: "",
  zipcode: "",
  isComplete: false,
  isDirty: false,
  canAddSourceAccount: false,
};

const isCheckingsComplete = (formState: AddAutopayFormInput) => {
  let isComplete =
    !!formState.abaNumber &&
    !!formState.accountNumber &&
    !!formState.firstName.trim() &&
    !!formState.lastName.trim() &&
    !!formState.addressLine1.trim() &&
    !!formState.city.trim() &&
    !!formState.selectedStateCode &&
    !!formState.zipcode;

  if (formState.selectedSourceAccountId === NEW_SOURCE_ACCOUNT) {
    isComplete =
      isComplete && formState.accountNumber === formState.confirmAccountNumber;
  } else {
    isComplete = isComplete && !!formState.selectedBankName;
  }
  return isComplete;
};

const formHasChanged = (
  formState: AddAutopayFormInput,
  initialFormState: AddAutopayFormInput,
) => {
  return (Object.keys(formState) as Array<keyof AddAutopayFormInput>).some(
    (key) =>
      key !== "isComplete" &&
      key !== "isDirty" &&
      key !== "canAddSourceAccount" &&
      formState[key] !== initialFormState[key],
  );
};

const isFormComplete = (
  formState: AddAutopayFormInput,
  initialFormState: AddAutopayFormInput,
) => {
  return (
    isCheckingsComplete(formState) &&
    formHasChanged(formState, initialFormState)
  );
};

export const useAddAutopayForm = (): [
  AddAutopayFormInput,
  {
    generalDispatch: (payload: Partial<AddAutopayFormInput>) => void;
  },
] => {
  const [formState, formDispatch] = useState<AddAutopayFormInput>(
    INITIAL_SOURCE_ACCOUNT_FORM_STATE,
  );

  const generalDispatch = (payload: Partial<AddAutopayFormInput>) => {
    formDispatch((prevFormState) => {
      const newFormState = {
        ...prevFormState,
        ...payload,
      };

      return {
        ...newFormState,
        isComplete: isFormComplete(
          newFormState,
          INITIAL_SOURCE_ACCOUNT_FORM_STATE,
        ),
        isDirty: formHasChanged(
          newFormState,
          INITIAL_SOURCE_ACCOUNT_FORM_STATE,
        ),
        canAddSourceAccount:
          isCheckingsComplete(newFormState) &&
          newFormState.selectedSourceAccountId === NEW_SOURCE_ACCOUNT,
      };
    });
  };
  return [formState, { generalDispatch }];
};
