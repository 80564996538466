import classNames from "classnames";
import { Col, Container, Row } from "react-grid-system";
import styles from "./Autopay.module.css";
import AddAutopay from "./AddAutopay";
import { GetAccountResponse } from "../../lib/api/models";
import { useGetAccountDetails } from "../../lib/api/queries";
import StyledButton from "../common/buttons/StyledButton";
import StyledError from "../StyledError";
import Spinner from "../common/Spinner";

const strings = {
  addAutopay: "Add Autopay",
  close: "Close",
};

type Props = {
  toolId: string;
  account: GetAccountResponse;
  onRequestClose: () => void;
  onDisplayGeneralErrors: (err: unknown) => void;
  className?: string;
};

export default function Autopay({
  toolId,
  account,
  onRequestClose,
  onDisplayGeneralErrors,
  className,
}: Props) {
  const {
    data: getAccountDetailsResponse,
    isLoading: isGetAccountDetailsResponseLoading,
    isError: isGetAccountDetailsResponseError,
    error: getAccountDetailsResponseError,
    refetch: getAccountDetailsResponseRefetch,
  } = useGetAccountDetails(account.currentDwbuid);

  return (
    <div className={classNames(styles.mainContainer, className)} id={toolId}>
      <Container>
        <Row>
          <Col md={11} className={styles.centerCol}>
            <h2 className={styles.header}>{strings.addAutopay}</h2>
          </Col>
          <Col md={1} className={styles.centerCol}>
            <StyledButton
              className={styles.closeButton}
              variant="secondary"
              onClick={onRequestClose}
            >
              {strings.close}
            </StyledButton>
          </Col>
        </Row>
        {isGetAccountDetailsResponseLoading ? (
          <Row>
            <Col>
              <Spinner className={styles.spinner} />
            </Col>
          </Row>
        ) : isGetAccountDetailsResponseError ? (
          <Row>
            <Col>
              <StyledError
                error={getAccountDetailsResponseError}
                errorTitle={strings.addAutopay}
                refetch={getAccountDetailsResponseRefetch}
              />
            </Col>
          </Row>
        ) : (
          <AddAutopay
            account={account}
            accountDetails={getAccountDetailsResponse}
            onDisplayGeneralErrors={onDisplayGeneralErrors}
          />
        )}
      </Container>
    </div>
  );
}
