import {
  CreditLineIncreaseScript,
  CreditLineUserData,
  Script,
  ScriptType,
} from "../../api/models";
import { centsToDollarString } from "../../formatting";
import styles from "./creditLineIncreaseScripts.module.css";
import { reportError } from "../../bugReporting";

export const creditLineIncreaseScript01 = (newMaxLimit: string): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that your account is currently eligible to be considered for a credit line increase. " +
      "To determine if you qualify for an increase, we will review your payment and credit history and will also consider your ability " +
      "to make the required minimum periodic payments under the terms of your account taking into account the amount of the increase. " +
      "Also, in connection with this determination, we may obtain a credit report from a consumer reporting agency. Would you like us to submit a request for you?",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "If Yes, continue with below process. If No, click the Close button to stop the request.",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "I will go ahead and submit your request. How much would you like me to request for you?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If request is more than the New Maximum Limit of ${newMaxLimit}, say:`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Based on your current limit, the maximum increase request I can submit for you is ${newMaxLimit}`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: `If request is less than the New Maximum Limit of ${newMaxLimit}:`,
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our Underwriting department may need to contact you to obtain additional information. What is the best number at which to reach you?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "If Customer won't provide phone number:",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "In case our Underwriting department needs to obtain additional information it would be helpful to have the best number at which to reach you.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "If Customer still won't provide phone number; Process without phone number.\n\n",
      },
      {
        text: "Click Submit CLI Request button to Submit.",
      },
    ],
  },
];

export const creditLineIncreaseScript02: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Your request has been routed to the Underwriting Department, and an Underwriting Department representative may call you back in the next " +
      "2 hours if additional information is needed to process your request.",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript03: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Based on your account history, you are not eligible at this time for a credit limit increase.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Transfer call to Collections - Extension 7654\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript04: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that your account is closed and therefore is not eligible for a credit limit increase. " +
      "Please hold while I transfer you to an agent who can assist you with your account.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Transfer call to Sioux Falls Collections - Extension 7679\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript05: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that your account is closed and therefore is not eligible for a credit limit increase. " +
      "Please hold while I transfer you to an agent who can assist you with your account.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript06: Script[] = [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Attempt to locate the new card and retry the Credit Line Increase process on that account.\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript07: Script[] = [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Attempt to locate the new card and retry the Credit Line Increase process on that account.\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript08: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue: "Our records show that your account is closed.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript09: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue: "First I need to verify your address.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text:
          "Verify and/or change the address, remove the Auth Flag, and retry the credit line increase process. If the address cannot be verified, transfer the call to Fraud - Extension 6777. " +
          "The Auth Flag remains on the account until the address is verified and hence the CLI request is declined.\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript10: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Your account is not eligible for a credit limit increase. Please hold while I transfer you to an agent who can assist with your account.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Transfer the call to Central Services - Extension 6750\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript11: Script[] = [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Please read notes to determine if the A Status was placed by Falcon, Internal Security, or Collections:\n\n",
      },
      {
        text: "If placed by Falcon:\n\n",
      },
      {
        text: "Identified by the FDW agent who applied the A Status per the Notes.",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that a temporary hold has been placed on your account. While the hold is in place, you are not eligible to be considered for a credit limit increase. " +
      "Please hold while I transfer you to one of our Security Service representatives who can assist in removing the hold. Once the temporary hold is removed, you can then request a credit limit increase. ",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Transfer call to FDW 800-377-1809.\n\n",
      },
      {
        text: "If placed by Internal Security:\n\n",
      },
      {
        text: "Identify agent who applied the A Status per the Notes if not FDW. The reason is identified by the content of the Note.\n\n",
      },
      {
        text: "If the account was OCL at the time the card was lost/stolen and is now current, remove the A status and retry the Credit Line Increase process.\n\n",
      },
      {
        text: "If the account required CM documentation (driver's license/social security card.)",
      },
    ],
  },
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that a temporary hold has been placed on your account. While the hold is in place, you are not eligible to be considered for a credit limit increase. " +
      "To have the hold removed, you must provide us a copy of your driver's license (or other unexpired government-issued identification evidencing nationality or residence and containing a photograph or other similar safeguard) and Social Security card. " +
      "After we receive these documents and the hold is removed, you can then request a credit limit increase. You may send a copy of these documents to us by fax at 1-605-232-6747 or by mail to:" +
      "\n\n1st Financial Bank USA\nAtt: Security Dept\nP.O. Box 1200\nN. Sioux City, SD 57049",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "If placed by Collections:\n\n",
      },
      {
        text: 'Identified by a collector note "drop from Cure/Pmt not made-up".\n\n',
      },
      {
        text: "Transfer call to the appropriate Collection Department\n\n\n\n",
      },
      {
        text: "If unable to determine why an A status was placed on the account:\n\n",
      },
      {
        text: "Complete a full account verification. When verified, remove the A status and retry the Credit Line Increase process.",
      },
    ],
  },
];

export const creditLineIncreaseScript12 = (
  currentCreditLimit: string,
): Script[] => [
  {
    scriptType: ScriptType.Dialogue,
    dialogue: `Since you have a credit limit of ${currentCreditLimit}, we are unable to process your request at this time. You are currently at the credit line maximum we offer at 1st Financial Bank USA. I apologize for any inconvenience.`,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript13: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Due to your limited credit experience with our company, we are unable to process your request at this time. Once you've had credit established with us for at least 6 months you can call to see if your account is eligible for an increase. " +
      "At that time, we will review your payment history, whether your account has ever been over the limit and if you are using your card. We will also obtain some information from an outside credit-reporting agency to help in determining if an increase will be granted.",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript14: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Since you've already had an increase in the last 6 months, we are unable to process your request at this time. Once six months have passed, please call the Bank to request another Credit Line increase. " +
      "At that time we will review your payment history, whether your account has ever been over the limit and if you are using your card. We will also obtain information from an outside credit-reporting agency to help in determining if an increase will be granted.",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript15: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that the balance on your account exceeds your credit limit and therefore you are not eligible to be considered for a credit limit increase. After you reduce the balance below the credit limit, you can then request to be considered " +
      "for a credit limit increase. Would you like to make a payment now?",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript16: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Due to your limited credit experience with our company, we are unable to process your request at this time. Once you've had credit established with us for at least 6 months and provided that you make timely payments and stay beneath your limit, " +
      "you can call to see if your account is eligible for an increase. At that time, we will review your payment history, whether your account has ever been over the limit and if you are using your card. We will also obtain information from an outside credit-reporting agency to help in determining if an increase will be granted.",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript17: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Based on your prior payment history, we are unable to process your request at this time. We suggest that you make at least 6 months of timely payments and stay beneath your limit. After you have made those 6 months of on-time payments, you can call to see if your account is eligible for an increase. " +
      "At that time, we will review your payment history, whether your account has ever been over the limit and if you are using your card. We will also obtain information from an outside credit-reporting agency to help in determining if an increase will be granted.",
    className: styles.alignTop,
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript18: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Your request has been routed to the Underwriting Department for review and they may contact you if additional information is needed to process your request.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript19: Script[] = [
  {
    scriptType: ScriptType.Dialogue,
    dialogue:
      "Our records show that your charging privileges are suspended. While your charging privileges are suspended, you are not eligible to be considered for a credit limit increase.",
  },
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

export const creditLineIncreaseScript20: Script[] = [
  {
    scriptType: ScriptType.Instruction,
    instructions: [
      {
        text: "Only one CLI transaction can be submitted per account search.\n\n",
      },
      {
        text: "Click Close button to End.",
      },
    ],
  },
];

const strings = {
  creditLineIncreaseMessage02: "CLI Request Submitted",
  creditLineIncreaseMessage03: "Account in Collections Status: Transfer Caller",
  creditLineIncreaseMessage04: "Account Closed: Charged Off",
  creditLineIncreaseMessage05:
    "Account Closed: Bankrupt or Interest Prohibited",
  creditLineIncreaseMessage06: "Account Closed: Lost",
  creditLineIncreaseMessage07: "Account Closed: Stolen",
  creditLineIncreaseMessage08: "Account Closed",
  creditLineIncreaseMessage09: "Auth Flag on Account: Research Required",
  creditLineIncreaseMessage10: "Account in Collection Program",
  creditLineIncreaseMessage11: "Research Required: Authorization Prohibited",
  creditLineIncreaseMessage12: "Maximum Credit Limit Reached",
  creditLineIncreaseMessage13: "Account Open Less Than 6 Months",
  creditLineIncreaseMessage14: "CLI Received Within the Past 6 Months",
  creditLineIncreaseScript15: "Account Over Credit Limit",
  creditLineIncreaseScript16: "Limited Credit Experience",
  creditLineIncreaseScript17: "Payment History",
  creditLineIncreaseScript18: "CLI Request Submitted",
  creditLineIncreaseMessage19: "Auth Prohibited & Status Reason Code=62",
};

export type CreditLineIncreaseScriptDetails = {
  message?: string;
  script: Script[];
  disableInput?: boolean;
  name: string;
};

export const getCreditLineIncreaseScriptDetails = (
  userData: CreditLineUserData,
  scriptName: CreditLineIncreaseScript,
  maxCreditLineCents: number,
): CreditLineIncreaseScriptDetails | undefined => {
  if (!scriptName) {
    return undefined;
  }

  let x: never;
  switch (scriptName) {
    case "CLI01":
      return {
        script: creditLineIncreaseScript01(
          centsToDollarString(maxCreditLineCents),
        ),
        name: "CLI01",
      };
    case "CLI02":
      return {
        message: strings.creditLineIncreaseMessage02,
        script: creditLineIncreaseScript02,
        name: "CLI02",
      };
    case "CLI03":
      return {
        message: strings.creditLineIncreaseMessage03,
        script: creditLineIncreaseScript03,
        disableInput: true,
        name: "CLI03",
      };
    case "CLI04":
      return {
        message: strings.creditLineIncreaseMessage04,
        script: creditLineIncreaseScript04,
        disableInput: true,
        name: "CLI04",
      };
    case "CLI05":
      return {
        message: strings.creditLineIncreaseMessage05,
        script: creditLineIncreaseScript05,
        disableInput: true,
        name: "CLI05",
      };
    case "CLI06":
      return {
        message: strings.creditLineIncreaseMessage06,
        script: creditLineIncreaseScript06,
        disableInput: true,
        name: "CLI06",
      };
    case "CLI07":
      return {
        message: strings.creditLineIncreaseMessage07,
        script: creditLineIncreaseScript07,
        disableInput: true,
        name: "CLI07",
      };
    case "CLI08":
      return {
        message: strings.creditLineIncreaseMessage08,
        script: creditLineIncreaseScript08,
        disableInput: true,
        name: "CLI08",
      };
    case "CLI09":
      return {
        message: strings.creditLineIncreaseMessage09,
        script: creditLineIncreaseScript09,
        disableInput: true,
        name: "CLI09",
      };
    case "CLI10":
      return {
        message: strings.creditLineIncreaseMessage10,
        script: creditLineIncreaseScript10,
        disableInput: true,
        name: "CLI10",
      };
    case "CLI11":
      return {
        message: strings.creditLineIncreaseMessage11,
        script: creditLineIncreaseScript11,
        disableInput: true,
        name: "CLI11",
      };
    case "CLI12":
      return {
        message: strings.creditLineIncreaseMessage12,
        script: creditLineIncreaseScript12(
          centsToDollarString(userData.creditLimitCents),
        ),
        disableInput: true,
        name: "CLI12",
      };
    case "CLI13":
      return {
        message: strings.creditLineIncreaseMessage13,
        script: creditLineIncreaseScript13,
        disableInput: true,
        name: "CLI13",
      };
    case "CLI14":
      return {
        message: strings.creditLineIncreaseMessage14,
        script: creditLineIncreaseScript14,
        disableInput: true,
        name: "CLI14",
      };
    case "CLI15":
      return {
        message: strings.creditLineIncreaseScript15,
        script: creditLineIncreaseScript15,
        disableInput: true,
        name: "CLI15",
      };
    case "CLI16":
      return {
        message: strings.creditLineIncreaseScript16,
        script: creditLineIncreaseScript16,
        disableInput: true,
        name: "CLI16",
      };
    case "CLI17":
      return {
        message: strings.creditLineIncreaseScript17,
        script: creditLineIncreaseScript17,
        disableInput: true,
        name: "CLI17",
      };
    case "CLI18":
      return {
        message: strings.creditLineIncreaseScript18,
        script: creditLineIncreaseScript18,
        name: "CLI18",
      };
    case "CLI19":
      return {
        message: strings.creditLineIncreaseMessage19,
        script: creditLineIncreaseScript19,
        disableInput: true,
        name: "CLI19",
      };
    case "CLI20":
      return {
        script: creditLineIncreaseScript20,
        name: "CLI20",
      };
    default:
      x = scriptName;
      reportError(
        new Error(
          "Programmer Error: Could not find script associated with the provided scriptName",
          {
            cause: scriptName,
          },
        ),
      );
      return x;
  }
};
